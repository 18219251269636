.location .box {
  position: relative;
  border-radius: 5px;
}
.location img {
  border-radius: 5px;
}
.location .overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  width: 100%;
  color: #fff;
  z-index: 222;
}
.location .overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 210px;
  background: rgba(233, 12, 12, 0.3);
  z-index: -1;
  margin: 20px;
  border-radius: 5px;
}
.about .btn2-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  margin-top: 20px;
}

.btn2 {
  background-color: #eb4242; /* Warna merah */
  color: #fff; /* Warna teks putih */
  padding: 10px 20px; /* Adjust padding for a more compact button */
  border: none; /* Menghapus border */
  border-radius: 5px; /* Memberikan sudut melengkung pada tombol */
  text-decoration: none; /* Menghapus dekorasi teks */
  cursor: pointer; /* Mengubah kursor saat diarahkan ke tombol */
  align-self: flex-end; /* Align the button to the bottom of the container */
  font-size: 18px; /* Set the font size */
  font-weight: bold; /* Make the text bold */
}

.btn2:hover {
  background-color: #f18888; /* Warna merah yang sedikit lebih gelap saat tombol dihover */
}

.location h5 {
  font-size: 18px;
  font-weight: 500;
}

.location label {
  color: #fff;
  margin-right: 20px;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .location .overlay::after {
    width: 280px;
    height: 170px;
  }
}
