@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.card {
  width: 20rem;
  border: none;
  border-radius: 8px;
  overflow: hidden; /* Mengatasi overflow gambar jika melebihi kartu */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animasi saat hover */
  cursor: pointer; /* Ubah kursor menjadi tangan saat dihover */
}

.card:hover {
  transform: translateY(-5px); /* Geser kartu ke atas saat hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Lebih tebal dan sedikit perluasan bayangan saat hover */
}

.card-img-top {
  width: 100%;
  height: 15rem; /* Tinggi gambar yang disesuaikan */
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-family: 'Poppins', sans-serif; /* Apply Poppins font to category name */
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px; /* Tambahkan jarak ke bawah untuk memisahkan judul dengan konten */
}

.card-text {
  font-family: 'Poppins', sans-serif; /* Apply Poppins font to category name */
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px; /* Tambahkan jarak ke bawah untuk memisahkan konten dengan tombol */
}

.btn {
  display: block; /* Ubah menjadi blok agar tombol mengambil lebar kartu */
  width: 100%; /* Gunakan lebar penuh */
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 0; /* Padding atas dan bawah */
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

.card-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-name {
  font-size: 13px;
  font-family: 'Poppins', sans-serif; /* Apply Poppins font to category name */
  color: #000; /* Black text color */
  background-color: #ccc; /* Gray background */
  border: 1px solid #ccc; /* Gray border */
  padding: 2px 8px; /* Padding inside the border */
  border-radius: 15px; /* Rounded corners */
}