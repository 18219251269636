.checkout-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    text-align: center;
    color: #1d1d1d;
}

.checkout-success-container i {
    color: #28a745;
    margin-bottom: 20px;
}

.checkout-success-container h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.checkout-success-container p {
    font-size: 18px;
    margin-bottom: 30px;
}

.checkout-success-container .btn-pembayaran {
    background-color: #3f42e9;
    color: #ececec;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
}

.checkout-success-container .btn-pembayaran:hover {
    background-color: #F3B95F;
}
