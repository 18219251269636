/* Footer container styling */
footer {
  background-color: #BB2525;
  padding: 60px 0;
  color: #141E46;
}

/* Footer grid container */
footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

/* Logo styling */
footer img {
  width: 150px;
}

/* Heading styling */
footer h2, footer h3 {
  font-weight: 500;
}

/* Paragraph styling */
footer p {
  color: #ffffff;
  margin: 20px 0;
}

/* Input styling */
footer input {
  background-color: #fcfbf9;
  padding: 17px;
  width: calc(100% - 120px); /* Adjust to fit the input and button within the container */
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #141E46;
  color: #141E46;
}

/* List styling */
footer ul {
  list-style-type: none;
  padding: 0;
}

/* List item styling */
footer ul li {
  color: #f4f4f7;
  margin-bottom: 20px;
}

/* Footer link styling */
.footer-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: normal; /* Make the text normal weight */
}

.footer-link:hover {
  text-decoration: underline;
}

/* Subscribe button styling */
.subscribe-button {
  background-color: #F3B95F;
  color: #141E46;
  border: none;
  padding: 17px;
  border-radius: 5px;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #FDE767;
  color: rgb(7, 5, 5);
}

/* Legal section styling */
.legal {
  text-align: center;
  padding: 20px;
  background: #141E46;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Input container styling */
.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Responsive adjustments */
@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .input-container {
    flex-direction: column;
    align-items: flex-start;
  }

  footer input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .subscribe-button {
    width: 100%;
  }
}

/* Specific styling for the contact section */
.footer-contact ul {
  padding-left: 0;
}

.footer-contact li {
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left; /* Align text to the left */
  color: #f4f4f7;
}

.footer-contact li span {
  font-weight: bold;
}

.footer-contact li a {
  color: #f4f4f7;
  text-decoration: none;
  margin-left: 5px;
}

.footer-contact li a:hover {
  text-decoration: underline;
}

/* Styling for Menu and Contact text */
footer h3 {
  font-weight: 600; /* Make the text slightly bolder */
  color: #ffffff; /* Make the text white */
}

footer ul li {
  color: #ffffff; /* Make the text white */
}
