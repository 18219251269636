.contact form div {
  display: flex;
}
.contact textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}
.contact input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}
.contact .form-group {
  display: flex;
  gap: 10px;
}
.contact form {
  padding: 30px;
}
.contact .btn-submit {
  background-color: navy;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.contact .btn-submit:hover {
  background-color: darkblue;
}
@media screen and (max-width: 768px) {
  .contact h4 {
    width: 100%;
    margin-bottom: 30px;
  }
  .contact .form-group {
    flex-direction: column;
  }
}
