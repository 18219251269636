/* Existing styles */

.card.border-custom {
  border-left: none !important;
  border-right: none !important;
  width: 90%;
  margin: 0 auto; /* Center the card horizontally */
}

.card.border-custom .card-body {
  border-left: none !important;
  border-right: none !important;
}

.img-thumbnail-custom {
  width: 150px; /* Adjust the width to make the image smaller */
  height: auto;
  margin: auto; /* Center the image vertically */
  display: block; /* Center the image horizontally */
}

.card-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%; /* Ensure it takes full height for vertical centering */
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the end (right) */
  margin-left: 80px; /* Push the controls to the far right */
}

.quantity-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.quantity-input {
  width: 60px;
  text-align: center;
  margin: 0 5px;
}

.btn-danger {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
}

.checkout-button {
  width: 150px; /* Set the desired width for the button */
  padding: 10px 0; /* Add padding for better appearance */
  text-align: center; /* Center the text within the button */
  background-color: #007bff; /* Optional: Set a background color */
  color: white; /* Optional: Set text color to white */
  border: none; /* Remove default border */
  border-radius: 5px; /* Optional: Add border radius for rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  margin-bottom: 20px;
}

.checkout-button:hover {
  background-color: #0056b3; /* Optional: Change background color on hover */
}

.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.empty-cart .fa-shopping-cart {
  font-size: 100px; /* Adjust the size of the cart icon */
  color: #a79999; /* Change the color of the cart icon */
  margin-bottom: 20px; /* Add some space below the icon */
}

.empty-cart p {
  font-size: 18px; /* Adjust the font size of the text */
  margin-bottom: 20px; /* Add some space below the text */
}

.empty-cart .shop-now-btn {
  background-color: #141E46; /* Set a background color */
  color: white; /* Set text color to white */
  padding: 10px 20px; /* Add padding for better appearance */
  border: none; /* Remove default border */
  border-radius: 5px; /* Add border radius for rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  text-decoration: none; /* Remove underline from the link */
}

.empty-cart .shop-now-btn:hover {
  background-color: #0056b3; /* Change background color on hover */
}

/* Loading animation */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f3f3;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #D04848; /* Red from palette */
  border-right: 8px solid #F3B95F; /* Orange from palette */
  border-bottom: 8px solid #FDE767; /* Yellow from palette */
  border-left: 8px solid #6895D2; /* Blue from palette */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Other existing styles... */

