.container-detail {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  box-sizing: border-box;
}

.img-fluid {
  max-width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
}

.detail-barang {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.detail-barang h2 {
  font-size: 20;
  font-weight: 600;
  margin-bottom: -3px;
  font-family: 'Poppins', sans-serif;
}

.detail-nama {
  text-align: start;
}

.detail-harga {
  font-size: 25px;
  font-weight: 600;
  color: #FF6262;
}

.detail-deskripsi {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.divider {
  border: none;
  border-top: 1px solid #555555;
  margin: 20px 0;
}

.detail-barang p {
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}

.detail-barang .form-label {
  font-size: 18px;
}

.detail-barang .form-control {
  width: 100px;
}

.detail-barang .btn {
  margin-top: 10px;
}

.text-danger {
  font-size: 16px;
  color: red;
}

/* Additional CSS for centering button and quantity */
.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.quantity-btn {
  font-size: 20px;
  background-color: #FF6262;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.quantity-input {
  width: 10px;
  text-align: center;
  margin: 0 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 5px;
}

/* Additional CSS for styling the button */
.btn-primary {
  margin-top: 10px;
  background-color: #141E46;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Additional CSS for WhatsApp button */
.btn-whatsapp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.btn-whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25D366;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.btn-whatsapp i {
  margin-right: 10px;
  font-size: 20px;
}

/* Detailproduk.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
