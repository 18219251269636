/* Container services */
.service {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Setiap kolom memiliki lebar minimum 250px */
  gap: 20px; /* Jarak antar kartu */
  justify-items: center; /* Menyusun item di tengah */
  margin-top: 50px;
}

/* Style untuk gambar pada kartu */
.service .card-img-top {
  width: 100%;
  height: 200px; /* Tinggi gambar */
  object-fit: cover; /* Gambar akan menutupi area gambar tanpa memperdistorsi aspek rasio */
  border-radius: 10px; /* Sudut melengkung */
}

/* Style untuk setiap kartu */
.service .card-service {
  width: 100%; /* Lebar kartu 100% */
  background-color: #fff;
  border-radius: 10px; /* Sudut melengkung untuk kartu */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Efek bayangan untuk kedalaman */
  transition: transform 0.3s ease; /* Efek transisi saat hover */
}


/* Style untuk body pada kartu */
.service .card-body {
  padding: 20px;
}

/* Style untuk judul pada kartu */
.service .card-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

/* Style untuk teks pada kartu */
.service .card-text {
  font-size: 16px;
  color: #666;
  text-align: left;
}

/* Style untuk tombol pada kartu */
.service .btn {
  width: 100%;
  margin-top: 20px;
  background-color: #007bff; /* Warna latar belakang tombol */
  color: #fff; /* Warna teks tombol */
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Efek transisi saat hover */
}

/* Style saat hover pada tombol */
.service .btn:hover {
  background-color: #0056b3; /* Warna latar belakang tombol saat hover */
}

/* Efek transisi saat hover pada kartu */
.service .card:hover {
  transform: translateY(-5px); /* Geser kartu ke atas saat hover */
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 40px; /* Sesuaikan dengan jarak dari konten di atasnya */
}

.pagination {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  display: inline;
}

.pagination a {
  color: #007bff;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #007bff;
  margin-right: 5px;
  border-radius: 4px;
}

.pagination a.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.filter-container {
  margin-left: 170px;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  margin-bottom: 20px;
  margin-top: 20px;
}

.search-container input {
  width: 70%;
  padding: 10px 20px;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-container input:focus {
  border-color: #007bff;
}

/* Category filter dropdown */
.category-filter select {
  padding: 10px 20px;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease; /* Smooth transition for border color */
}

.category-filter select:focus {
  border-color: #007bff; /* Border color on focus */
}
