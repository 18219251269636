/* Gopay.css */
.container {
    text-align: center;
}

h2 {
    font-size: 20px;
    margin-bottom: 8px;
}

h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

p {
    font-size: 14px;
    margin-bottom: 20px;
}

.qr-image {
    max-width: 250px;
    max-height: 250px;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
}
