@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff; /* Background color */
}

.checkout-container {
    padding: 20px;
}

h2 {
    color: #0c0c0c;
    font-weight: 700;
}

.card.border-custom {
    border: 1px solid #141E46 ;
    width: 90%;
    margin: 0 auto; /* Center the card horizontally */
    background-color: #ffffff; /* Match background */
}

.card.border-custom .card-body {
    background-color: #ffffff; /* Match background */
    width: 100%; /* Set the width to 100% of its parent container */
    max-width: 400px; /* Set a maximum width */
    height: auto; /* Set height to auto */
    min-height: 150px; /* Set a minimum height */
    margin: 0 auto; /* Center the card horizontally */
}

.img-thumbnail-custom {
    width: 100px; /* Adjust the width to make the image smaller */
    height: 100px;
    margin: auto; /* Center the image vertically */
    display: block; /* Center the image horizontally */
}

.card-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%; /* Ensure it takes full height for vertical centering */
}

.card-title {
    color: #080808;
    font-weight: 700;
}

.card-text {
    color: #030303;
    font-weight: 500;
}

.quantity-control {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align items to the end (right) */
    margin-left: 80px; /* Push the controls to the far right */
}

.quantity-btn {
    background-color: #6895D2;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
}

.quantity-btn:hover {
    background-color: #D04848;
}

.quantity-input {
    width: 60px;
    text-align: center;
    margin: 0 5px;
}

.btn {
    font-size: 13px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    margin-left: 10px;
}

.btn-success-confirm {
    background-color: #6895D2;
    color: #FFF;
}

.btn-success-confirm:hover {
    background-color: #D04848;
    color: white;
}

.btn-primary-back {
    background-color: #F3B95F;
    color: #FFF;
}

.btn-primary-back:hover {
    background-color: #D04848;
    color: white;
}

.btn-finish {
    background-color: #6895D2;
    color: #FFF;
}

.btn-finish:hover {
    background-color: #263194;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.payment-button {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.payment-button.bank-transfer {
    background-color: #1530ca; /* Color for bank transfer */
    color: white;
}

.payment-button.gopay {
    background-color: #3fc245; /* Color for Gopay */
    color: white;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .button-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .button-container .btn {
        margin-left: 0;
        margin-bottom: 10px; /* Space between buttons in column layout */
        width: 100%; /* Full width on smaller screens */
    }
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #F3B95F;
    width: 90%;
    max-width: 400px;
    border-radius: 5px;
    text-align: center;
}

textarea.form-control.mb-4 {
    background-color: #ffffff;
    border: 1px solid #F3B95F;
    border-radius: 5px;
}

textarea.form-control.mb-4:focus {
    border-color: #F3B95F;
    box-shadow: none;
}

.text-danger {
    color: #6195e4;
    font-weight: 500;
}

.gopay-qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
}

.gopay-qr-image {
    width: 180px;
    height: 180px;
}
