@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.recomended {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.card-section {
  border: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.card-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body {
  padding: 20px;
}

.card-titles {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-texts {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.card-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-name {
  font-size: 0.8rem;
  color: #000;
  background-color: #ccc;
  border: 1px solid #ccc;
  padding: 2px 8px;
  border-radius: 15px;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.lihat-semua-btn {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: transparent;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.lihat-semua-btn:hover {
  color: #0056b3;
}

.lihat-semua-btn .fa-arrow-right {
  margin-left: 8px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .recomended {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .card-img {
    height: 180px;
  }
}

@media (max-width: 768px) {
  .heading-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .lihat-semua-btn {
    margin-top: 10px;
  }

  .recomended {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .card-img {
    height: 150px;
  }
}

@media (max-width: 576px) {
  .recomended {
    grid-template-columns: 1fr;
  }

  .card-img {
    height: 120px;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
