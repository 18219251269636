.payment-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ececec;
  }
  
  .title, .subtitle {
    text-align: center;
    margin-bottom: 10px;
    color: #1d1d1d;
  }
  
  .pesanan-card {
    border: 1px solid #bfbfbf;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fdfdfc;
  }
  
  .pesanan-card h3 {
    margin-top: 0;
    color: #1d1d1d;
  }
  
  .pesanan-card p {
    text-align: left;
    color: #1d1d1d;
  }
  
  .pesanan-card button {
    margin-right: 10px;
    background-color: #141E46; /* Warna biru */
    color: #ececec;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pesanan-card button:hover {
    background-color: #F3B95F; /* Warna efek hover */
  }
  
  .img-thumbnail-custom {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .card.border-custom {
    border: 1px solid #1d1d1d;
    border-radius: 8px;
    background-color: #ececec;
  }
  
  .card-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(19, 19, 19, 0.4);
  }
  
  .modal-content {
    background-color: #ececec;
    margin: auto;
    padding: 20px;
    border: 1px solid #bfbfbf;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
  }
  
  .close {
    color: #1d1d1d;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #b50717;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-content h2 {
    margin-top: 0;
    color: #1d1d1d;
    font-size: 24px;
    font-weight: bold;
  }

  .modal-divider {
    border: 0;
    height: 1px;
    background: #696363;
    margin: 10px 0;
  }
  
  .modal-content p {
    font-size: 18px;
    font-weight: bold;
    color: #1d1d1d;
  }
  
  .modal-content input[type="file"] {
    display: block;
    margin: 10px 0;
  }
  
  .modal-content button {
    background-color: #141E46; /* Warna biru */
    color: #ececec;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #F3B95F; /* Warna efek hover */
  }
  
  .text-danger {
    color: #b50717;
    margin-top: 10px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #ececec;
    color: #1d1d1d;
  }
  
  .empty-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    text-align: center;
    color: #1d1d1d;
  }
  
  .empty-payment i {
    color: #6072b3;
    margin-bottom: 20px;
  }
  
  .empty-payment p {
    font-size: 20px; /* Ukuran lebih besar */
    font-weight: bold; /* Teks agak bold */
    margin-bottom: 20px; /* Jarak antara teks dan tombol */
  }
  
  .empty-payment .btn-belanja {
    background-color: #3f42e9;
    color: #ececec;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .empty-payment .btn-belanja:hover {
    background-color: #F3B95F; /* Warna efek hover */
  }

  /* Pembayaran.css */

.empty-history {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
}

.empty-history i {
    color: #60676b;
    margin-bottom: 20px;
}

.empty-history p {
    font-size: 18px;
    color: #1d1d1d;
}

.empty-history .btn-belanja {
    background-color: #141E46;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.empty-history .btn-belanja:hover {
    background-color: #2980b9;
}

/* Loading spinner */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Skeleton loader */
  .skeleton-card {
    width: 100%;
    height: 200px;
    background: #e0e0e0;
    border-radius: 10px;
    margin-bottom: 20px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  