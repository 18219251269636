.awards {
  background-color: #141E46;
  color: #fff;
  text-align: center;
}
.awards .heading {
  display: flex;
  flex-direction: column-reverse;
}
.awards .heading p {
  color: #27ae60;
}
.awards .heading h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 600;
}
.awards .icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: auto;
  margin-top: 50px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4% 50%;
  font-size: 36px;
  color: #ffffff;
}
.awards h1 {
  font-size: 50px;
}
.awards p {
  color: rgb(245, 239, 239);
}
